import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap"

class ConfirmDialog extends Component {

    static = {
        self: null
    }

    state = {
        dialogConfirm: {
            showConfirmDialog: false,
            title: "",
            message: "",
            handleClose: null,
            proceedCallBack: null,
            proceedButtonLabel: null,
            inputBox: false,
            inputPlaceHolder: "",
            defaultValue: ""
        },
        inputText: ""
    }

    static showConfirmDlg(dialogConfirm) {
        console.log("showConfirmDlg: ", dialogConfirm);
        if (ConfirmDialog.self) {
            ConfirmDialog.self.setState({ dialogConfirm, inputText: dialogConfirm.defaultValue });
            // ConfirmDialog.self.inputText = dialogConfirm.defaultValue;
        }
    }

    componentDidMount() {
        ConfirmDialog.self = this;
    }

    handleClose = () => {
        const { dialogConfirm } = this.state;
        dialogConfirm.showConfirmDialog = false;
        dialogConfirm.inputBox = false;
        this.setState({ dialogConfirm });
    }

    handleProceedClick = () => {
        if (this.state.dialogConfirm.proceedCallBack) {
            this.state.dialogConfirm.proceedCallBack();
        }
        this.handleClose();
    }

    handleChange = ({ currentTarget: input }) => {
        console.log("handleChange:", input.value);
        //const title = this.state.title;
        const inputText = input.value;
        this.setState({ inputText });
    };

    validate = () => {
        if (this.state.inputText && this.state.inputText.trim() === "") {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        console.log("handleSubmit() inputText: ", this.state.inputText);

        if (this.validate()) {
            console.log("handleSubmit() VALID!");
            this.state.dialogConfirm.proceedCallBack(this.state.inputText);
            this.handleClose();
        }
    }

    renderInputField = () => {

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                    {/* <input autoFocus type="text" className="form-control" value={this.state.dialogConfirm.defaultValue} onChange={this.handleChange} /> */}
                    <input autoFocus type="text" className="form-control" value={this.state.inputText} onChange={this.handleChange} />

                    {/* <input autoFocus type="text" className="form-control" placeholder={this.state.dialogConfirm.inputPlaceHolder} onChange={this.handleChange} /> */}
                    {/* <input autoFocus type="text" className="form-control" placeholder={"xxxxx"} onChange={this.handleChange} /> */}
                    <div className="input-group-append">
                        <input disabled={!this.validate()} className="btn btn-primary ml-2" type="submit"></input>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div>
                <Modal show={this.state.dialogConfirm.showConfirmDialog} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.dialogConfirm.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.dialogConfirm.message}
                        {this.state.dialogConfirm.inputBox && this.renderInputField()}
                    </Modal.Body>



                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        {!this.state.dialogConfirm.inputBox && <Button variant="primary" onClick={this.handleProceedClick}>
                            {this.state.dialogConfirm.proceedButtonLabel}
                        </Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ConfirmDialog;