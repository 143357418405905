import React, { Component } from 'react';
import { InputGroup } from "react-bootstrap"


class CheckboxSelect extends Component {

    state = {
        checkboxChecked: false
    }

    handleCheckBox = (e) => {
        console.log("CheckboxSelect handleCheckbox: ", e);
        const checkboxChecked = !this.state.checkboxChecked;
        this.props.item.checkboxChecked = checkboxChecked;
        this.setState({ checkboxChecked });
    }

    render() {
        return (
            <InputGroup.Checkbox type="checkbox" checked={this.state.checkboxChecked} onChange={this.handleCheckBox} />
        );
    }
}

export default CheckboxSelect;