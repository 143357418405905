import React from "react";
import TableHeader from "./TableHeader";
//import Movie from "./Movie";
import TableBody from "./TableBody";

const Table = ({ columns, items }) => {

    return (

        <table className="table table-sm table-striped" style={{ fontSize: '12px' }}>
            <TableHeader columns={columns} />
            <TableBody items={items} columns={columns} />
        </table >
    );
};

export default Table;
