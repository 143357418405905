import React, { Component } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";

class TableCell extends Component {
    state = {
        counter: 0,
        changed: false
    }

    constructor() {
        super();
        this.myRef = React.createRef();
    }

    onChange = (e) => {
        const newValue = e.currentTarget.innerText;

        if (newValue == _.get(this.props.item, this.props.column.path)) {
            console.log("no change, same value: ", newValue);
            return;
        }

        console.log("BEFORE CHANGE:", this.props.item);

        let changed = this.state.changed || (this.props.item.changed ? this.props.item.changed : false);
        console.log("changed: ", changed);

        if (this.props.column.isNumber) {
            const newNumber = Number(newValue);
            console.log("newNumber: ", newNumber);

            if (isNaN(newNumber) === false) {

                console.log('is a number... newNumber: ' + newNumber);
                _.set(this.props.item, this.props.column.path, newNumber);
                changed = true;
            } else {
                console.log("Error, number field but value is NOT a number...:", newValue);
                toast.error("ERROR, " + this.props.column.label + " must be a number. Invalid number:" + newValue);
                this.myRef.current.innerText = this.newValue = _.get(this.props.item, this.props.column.path);
            }

        } else {
            console.log("Text field, setting newValue:", newValue);
            _.set(this.props.item, this.props.column.path, newValue);
            changed = true;
        }

        if (changed) {
            this.props.item.changed = changed;
        }

        const counter = this.state.counter + 1;
        this.setState({ counter, changed });
    }

    getChangedClass = () => {
        if (this.state.changed && this.props.item.changed) {
            return "changedRow";
        } else {
            return "";
        }
    }

    // (1.005).toFixed(15)
    round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    getValue = () => {
        const value = _.get(this.props.item, this.props.column.path);
        if (this.props.column.isNumber) {
            return this.round(value, 2);
        } else {
            return value;
        }
    }

    render() {
        const value = _.get(this.props.item, this.props.column.path);
        return (
            <td ref={this.myRef} key={this.props.item.id + value} className={this.getChangedClass()}
                contentEditable={this.props.column.contentEditable ? true : false} suppressContentEditableWarning={true}
                //style={{}}
                style={this.props.column.style ? this.props.column.style : {}}
                // className={this.props.column.colorBcgCls ? this.props.column.colorBcgCls : ""}
                // onInput={this.onChange}
                onBlur={this.onChange}
            >
                {/* {value} */}
                {this.getValue()}
            </td >
        );
    }
}

export default TableCell;