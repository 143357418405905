import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


var ws = null;
var reconnectCounter = 0;
var lastMessageTimestamp;
var msgNumberArray = [];
var msgNumber = 1;

var registeredMessageHandlers = [];


const URL = process.env.REACT_APP_WS; //'wss://localhost/LotBuilder/smalllotEP/';
//const URL = 'wss://zip2sale.com/LotBuilder/smalllotEP/';



// addMessageHandler, removeHandler

export function addMessageHandler(type, handler) {

    const msgHandler = {
        messsageType: type,
        messageHandler: handler
    };

    console.log("Registering message Handler: ", msgHandler);
    registeredMessageHandlers.push(msgHandler);
}

export function removeHandler(handler) {
    registeredMessageHandlers = registeredMessageHandlers.filter((msgHandler) => { return msgHandler.messageHandler != handler });
    console.log("websockets.removeHandler()registeredMessageHandlers:", registeredMessageHandlers);
}

export function isConnected() {
    if (ws && ws.readyState === WebSocket.OPEN) {
        return true;
    } else {
        return false;
    }
}

export function deleteImages(imageIds, callbackf, errorCallback) {

    var jsonObj = JSON.stringify(
        {
            "type": "DELETE_IMAGES",
            "imageIds": imageIds,
            "msgNumber": storeCallBack(callbackf, errorCallback)
        });

    console.log('Sending GET_BOXES MESSAGE => ' + jsonObj);
    ws.send(jsonObj);

}

export function requestBoxes(manualManiestId, userId, handleBoxAndItems, errorCallback) {
    // {"type":"GET_BOXES","manualManiestId":204,"userId":6}
    console.log("manualManiestId: ", manualManiestId);
    console.log("userId: ", userId);

    var jsonObj = JSON.stringify(
        {
            "type": "GET_BOXES",
            "manualManiestId": manualManiestId,
            "userId": userId,
            "msgNumber": storeCallBack(handleBoxAndItems, errorCallback)
        });

    console.log('Sending GET_BOXES MESSAGE => ' + jsonObj);
    ws.send(jsonObj);

}

export function requestAllBoxes() {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_ALL_BOXES"
        });

        console.log('Sending GET_ALL_BOXES MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}



export function getManifests(statusFilters, manualManiestId, userId, boxCreatedCallsBack, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_MANIFESTS",
            "manifestStatus": statusFilters,
            "manualManiestId": manualManiestId,
            "userId": userId,
            "msgNumber": storeCallBack(boxCreatedCallsBack, errorCallback)
        });

        console.log('Sending GET_MANIFESTS MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function createBoxRequest(label, manualManiestId, boxCreatedCallsBack, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "CREATE_BOX",
            "label": label,
            "manualManiestId": manualManiestId,
            "msgNumber": storeCallBack(boxCreatedCallsBack, errorCallback)
        });

        console.log('Sending CREATE_BOX MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function updateBoxData(boxData, boxUpdatedCallsBack, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "UPDATE_BOX",
            "boxData": boxData,
            "msgNumber": storeCallBack(boxUpdatedCallsBack, errorCallback)
        });

        console.log('Sending UPDATE_BOX MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function updateManifestRequest(label, manualManiestId, callsBack, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "UPDATE_MANIFEST",
            "label": label,
            "manualManiestId": manualManiestId,
            "msgNumber": storeCallBack(callsBack, errorCallback)
        });

        console.log('Sending UPDATE_MANIEST MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function createManifestRequest(label, callsBack, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "CREATE_MANIFEST",
            "label": label,
            "msgNumber": storeCallBack(callsBack, errorCallback)
        });

        console.log('Sending CREATE_MANIFEST MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}


export function getBoxItemns(boxIds, handleBoxItems) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_ITEMS_FOR_BOXES",
            "manualBoxIds": boxIds,
            "msgNumber": storeCallBack(handleBoxItems)
        });

        console.log('Sending GET_ITEMS_FOR_BOXES MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function getBoxAndItems(boxIds, handleBoxAndItems, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_BOX_AND_ITEMS",
            "manualBoxIds": boxIds,
            "msgNumber": storeCallBack(handleBoxAndItems, errorCallback)
        });

        console.log('Sending GET_BOX_AND_ITEMS MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function getLotItemns(manualManiestId) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_ITEMS_FOR_LOT",
            "manualManiestId": manualManiestId
        });

        console.log('Sending GET_ITEMS_FOR_LOT MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function addItemByUPC(UPC, boxIds, callback, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "ADD_ITEM",
            "UPC": UPC,
            "manualBoxIds": boxIds,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending ADD_ITEM MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function updateItems(items, callback, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "UPDATE_ITEMS",
            "items": items,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending UPDATE_ITEMS MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}



export function assignBoxesToManifest(manifestId, boxIds) {

    // {"type":"ASSIGN_BOXES","manifestLabel":"","manualManiestId":212,"userId":-1,"manualBoxIds":[549,550]}
    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "ASSIGN_BOXES",
            "manualManiestId": manifestId,
            "manualBoxIds": boxIds
        });

        console.log('Sending ASSIGN_BOXES MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function deleteBoxesById(manualBoxIds, callback, errorCallback) {

    // {"type":"ASSIGN_BOXES","manifestLabel":"","manualManiestId":212,"userId":-1,"manualBoxIds":[549,550]}
    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "DELETE_BOX",
            "manualBoxIds": manualBoxIds,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending DELETE_BOX MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function deleteItemsById(itemIds, callback, errorCallback) {

    // {"type":"ASSIGN_BOXES","manifestLabel":"","manualManiestId":212,"userId":-1,"manualBoxIds":[549,550]}
    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "DELETE_ITEM",
            "itemIds": itemIds,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending DELETE_ITEM MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function UPLOAD_FILE_FOR_MANIFEST(manualManiestId, remoteFileName, callback, errorCallback) {

    // {"type":"ASSIGN_BOXES","manifestLabel":"","manualManiestId":212,"userId":-1,"manualBoxIds":[549,550]}
    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "UPLOAD_FILE_FOR_MANIFEST",
            "manualManiestId": manualManiestId,
            "remoteFileName": remoteFileName,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending UPLOAD_FILE_FOR_MANIFEST MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}

export function getImagesForManifest(manualManiestId, callback, errorCallback) {

    if (ws && ws.readyState === WebSocket.OPEN) {

        var jsonObj = JSON.stringify({
            "type": "GET_MANIFEST_IMAGES",
            "manualManiestId": manualManiestId,
            "msgNumber": storeCallBack(callback, errorCallback)
        });

        console.log('Sending GET_MANIFEST_IMAGES MESSAGE => ' + jsonObj);
        ws.send(jsonObj);
    }
}


function storeCallBack(callbackFunc, errorCallback = null) {
    //const ts = Date.now();
    ++msgNumber;
    const callElem = {
        msgNum: msgNumber,
        callbackf: callbackFunc,
        errorCallbackf: errorCallback
    }
    msgNumberArray.push(callElem);
    console.log("Saved callback to msgNumberArray: ", msgNumberArray);
    console.log("msgNumber: ", msgNumber);

    return msgNumber;
}

export function sendRawData(rawData) {
    console.log("websockets.sendRawData");
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(rawData);
    }
}



function envokeErrorCallbackFunc(msgNum, ...args) {

    msgNumberArray.map(ev => {
        if (ev.msgNum == msgNum && ev.errorCallbackf) {
            ev.errorCallbackf(args)
        }
    });

    msgNumberArray = msgNumberArray.filter(val => val.msgNum != msgNum);
}


function envokeCallbackFunc(msgNum, ...args) {

    msgNumberArray.map(ev => {
        if (ev.msgNum == msgNum && ev.callbackf) {
            ev.callbackf(args)
        }
    });

    msgNumberArray = msgNumberArray.filter(val => val.msgNum != msgNum);
}

export function initWebsockets(userInfoCallback) {

    console.log("Running initWebsockets...");

    // const msgNum = storeCallBack(userInfoCallback);

    if (ws && ws.readyState === WebSocket.CLOSED) {
        let str = '';
        str = str.padEnd(reconnectCounter, '.');
        const reconnectMsg = 'Connecting to server, trying to reconnect' + str.padEnd(reconnectCounter, '.');
        toast.error(reconnectMsg);
        console.log(reconnectMsg);
        reconnectCounter = (reconnectCounter + 1) % 4;
    }

    ws = new WebSocket(URL);


    ws.onopen = function (e) {
        console.log('onopen(), state: ' + ws.readyState);
        //  toast.success('onopen(), state: ' + ws.readyState, {
        //     position: toast.POSITION.TOP_CENTER
        // });

        var jsonObj = JSON.stringify({
            "type": "GET_USER_INFO",
            "msgNumber": storeCallBack(userInfoCallback)
        });

        console.log('Sending GET_USER_INFO MESSAGE => ' + jsonObj);
        ws.send(jsonObj);

    }

    ws.onclose = function () {
        console.log('onclose');
    }

    ws.onerror = function (event) {
        toast.error("network error...", event);
        console.log('onerror: ', event);
    }

    ws.onmessage = function (c) {

        lastMessageTimestamp = new Date().getTime();

        console.log('onmessage: ');
        console.log(c);

        // console.log(c.data);
        console.log("message length: " + c.data.length + " bytes");

        var msgData = (JSON.parse(c.data));

        console.log(msgData);

        // var registeredMessageHandlers = [];
        // const msgHandler = {
        //     messsageType: type,
        //     messageHandler: handler
        // };

        if (registeredMessageHandlers) {

            registeredMessageHandlers.map((msgHandler) => {
                if (msgHandler.messsageType == msgData.status) {
                    msgHandler.messageHandler(msgData);
                }
            });
        }

        switch (msgData.status) {
            case 'ALL_BOXES':
                console.log('ALL_BOXES');
                break;

            case 'GET_MANIFESTS_REPLY':
                console.log('GET_MANIFESTS_REPLY', msgData.manualLots);
                envokeCallbackFunc(msgData.msgNumber, msgData.manualLots);
                break;

            case 'BOXES_FOUND':
                console.log('BOXES_FOUND', msgData.boxData);
                envokeCallbackFunc(msgData.msgNumber, msgData.boxData);
                break;

            case 'DELETED_IMAGES':
                console.log('DELETED_IMAGES', msgData.imageIds);
                envokeCallbackFunc(msgData.msgNumber, msgData.imageIds);
                break;


            case 'BOX_UPDATED':
                console.log('BOX_UPDATED', msgData.boxData);
                envokeCallbackFunc(msgData.msgNumber, msgData.boxData);
                break;


            case 'DELETED_BOXES':
                console.log('DELETED_BOXES', msgData.manualBoxIds);
                envokeCallbackFunc(msgData.msgNumber, msgData.manualBoxIds);
                break;

            case 'DELETED_ITEMS':
                console.log('DELETED_ITEMS, item ids: ', msgData.itemIds);
                envokeCallbackFunc(msgData.msgNumber, msgData.itemIds);
                break;


            case 'UPDATED_ITEMS':
                console.log('UPDATED_ITEMS, boxData: ', msgData.items);
                envokeCallbackFunc(msgData.msgNumber, msgData.items);
                break;

            case 'BOX_CREATED':
                console.log('BOX_CREATED, boxData: ', msgData.boxData);
                envokeCallbackFunc(msgData.msgNumber, msgData.boxData);
                break;

            case 'USER_INFO':
                console.log('USER_INFO, userId: ', msgData.userId);
                const userInfo = { userId: msgData.userId, userLogin: msgData.userLogin, isAdmin: msgData.isAdmin };
                envokeCallbackFunc(msgData.msgNumber, userInfo);

                //envokeCallbackFunc(msgData.msgNumber, msgData.userId, msgData.userLogin, msgData.isAdmin);
                break;

            case 'MANIFEST_IMAGES':
                console.log('MANIFEST_UPDATED', msgData.images);
                envokeCallbackFunc(msgData.msgNumber, msgData.images);
                break;

            case 'MANIFEST_UPDATED':
                console.log('MANIFEST_UPDATED', msgData.manualLots);
                envokeCallbackFunc(msgData.msgNumber, msgData.manualLots);
                break;

            case 'MANIFEST_CREATED':
                console.log('MANIFEST_CREATED', msgData.manualLots);
                envokeCallbackFunc(msgData.msgNumber, msgData.manualLots);
                break;

            case 'BOXES_UPDATED':
                console.log('BOXES_UPDATED boxData: ', msgData.boxData);
                break;

            case 'ITEM_ADDED':
                console.log('ITEM_ADDED boxData: ', msgData.items);
                envokeCallbackFunc(msgData.msgNumber, msgData.items);
                break;


            case 'FILE_UPLOAD_READY':
                console.log('FILE_UPLOAD_READY manualManiestId: ' + msgData.manualManiestId + " remoteFileName: " + msgData.remoteFileName);
                console.log('FILE_UPLOAD_READY msgData: ', msgData);
                envokeCallbackFunc(msgData.msgNumber, msgData);
                break;

            case 'BOX_ITEMS':
                console.log('BOX_ITEMS items: ', msgData.items);
                envokeCallbackFunc(msgData.msgNumber, msgData.items);
                break;

            case 'BOX_AND_ITEMS':
                console.log('BOX_AND_ITEMS items: ', msgData.items);
                console.log('BOX_AND_ITEMS boxData: ', msgData.boxData);

                const data = {
                    boxes: msgData.boxData,
                    items: msgData.items
                }
                //envokeCallbackFunc(msgData.msgNumber, msgData.boxData);
                envokeCallbackFunc(msgData.msgNumber, data);
                break;

            case 'ERROR':
                console.log('ERROR', msgData.errorMessage);
                envokeErrorCallbackFunc(msgData.msgNumber, msgData.errorMessage);
                break;

            default:
                break;
        }
    }

}



export function disconnect() {
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
    }
}

export default {
    initWebsockets, isConnected, requestBoxes, disconnect, createBoxRequest, createManifestRequest,
    assignBoxesToManifest, deleteBoxesById, getManifests, sendRawData, updateBoxData, UPLOAD_FILE_FOR_MANIFEST,
    getImagesForManifest, addMessageHandler, removeHandler, deleteImages
};
