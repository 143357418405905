import React, { Component } from 'react';
import { Form, Button } from "react-bootstrap"
import $ from "jquery";
import NavBar from './NavBar';
import Spinner from 'react-bootstrap/ProgressBar'
import { UPLOAD_FILE_FOR_MANIFEST, deleteImages, getManifests, getImagesForManifest } from '../services/websockets'
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import { NavLink } from "react-router-dom";
import FileUploaderSpinner from './FileUploaderSpinner';
import ImageTable from './ImageTable';
import ConfirmDialog from './common/ConfirmDialog';


class LotImageManager extends Component {

    // this.props.lotData
    // this.props.fileList

    state = {
        images: null,
        imagesToUpload: null,
        lotData: null
    }

    componentDidMount = () => {
        // -----------------------------------
        let xOffset = 100;
        let yOffset = 10;


        $(document).on("mouseenter", ".preview", function (e) {

            console.log('this: ');
            console.log(this);

            //$("body").append("<img width=\"" + width + "\" height=\"" + height + "\" id=\"preview\" src='" + this.alt + "' alt='Image preview' />");
            $("body").append("<img width=\"500\" height=\"auto\" id=\"preview\" src='" + this.alt + "' alt='Image preview' />");
            $("#preview")
                .css("top", (e.pageY - xOffset) + "px")
                .css("left", (e.pageX + yOffset) + "px")
                .fadeIn("fast");
        });

        // display image on click
        $(document).on("click", ".preview", function (e) {
            console.log('image clicked!');
            // var $xxx = $('<img />', { src: this.alt, style: "border:5px solid black;" });
            // var $xxx = $('<img />', { src: this.alt, style: "border:5px solid black;max-width: 100 %; max-height: 100 %; margin-left: auto; margin-right: auto; display: block;" });
            // max - width: 100 %; max - height: 100 %; margin - left: auto; margin - right: auto; display: block;
            var $xxx = $('<img />', { src: this.alt, style: "border:5px solid black;max-width: 100%;max-height: 100%;margin-left: auto;margin-right: auto;display: block;" });

            $('#divLargerImage').html($xxx);


            $('#divLargerImage').css("position", "absolute")
                .css("top", (($(window).height() - $('#divLargerImage').outerHeight()) / 2) + $(window).scrollTop() + "px")
                .css("left", (($(window).width() - $('#divLargerImage').outerWidth()) / 2) + $(window).scrollLeft() + "px");
            $('#divLargerImage').show();


        });


        $('#divLargerImage').add($('#divOverlay')).click(function () {
            $('#divLargerImage').add($('#divOverlay')).fadeOut(function () {
                $('#divLargerImage').empty();
            });
        });

        $(document).on("mousemove", ".preview", function (e) {
            $("#preview")
                .css("top", (e.pageY - xOffset) + "px")
                .css("left", (e.pageX + yOffset) + "px")
                .fadeIn("fast");
        });

        $(document).on("mouseleave", ".preview", function (e) {
            //this.title = this.t;
            $("#preview").remove();
        });

        // -----------------------------------
        this.initialize();
    }

    initialize = () => {
        let lotId = null;
        if (this.props.match.params.lotId) {
            lotId = this.props.match.params.lotId;
            NavBar.showMessage(<span className="badge badge-primary">Fetching lot data...</span>);
            console.log("initialize lotId: " + lotId);
            const statusFilters = ["NOT_LISTED", "LISTED", "SOLD"];
            getManifests(statusFilters, lotId, -1, this.handleGetManifestMessage, this.handleErrorMessage);
            getImagesForManifest(lotId, this.handleManifestImages, this.handleErrorMessage);
        } else {
            console.log("initialize id is not set!");
        }
    }

    addImage = (img) => {
        console.log("addImage img: ", img);
        let { images } = this.state;
        if (images == null) {
            images = [];
        }

        images.push(img);

        console.log("addImage images: ", images);
        this.setState({ images });
    }

    handleManifestImages = (data) => {
        const images = data[0];
        console.log("handleManifestImages data: ", images);
        NavBar.showMessage(<span className="badge badge-success">Images loaded...</span>);
        this.setState({ images });
    }

    handleGetManifestMessage = (data) => {
        const lotData = data[0][0];
        console.log("handleGetManifestMessage: ", lotData);
        NavBar.showMessage(<span className="badge badge-success">Manifest loaded...</span>);
        this.setState({ lotData });
    }

    handleErrorMessage = (err) => {
        console.log("handleGetManifestErrorMessage: ", err[0]);
        NavBar.showMessage(<span className="badge badge-warning">{err[0]}</span>);
    }

    handleUploadCompletion = () => {
        console.log("handleUploadCompletion");
    }

    handleUploadCancel = () => {
        console.log("handleUploadCancel");
        getImagesForManifest(this.state.lotData.id, this.handleManifestImages, this.handleErrorMessage);
    }

    handleFileSelection = (e) => {
        const imagesToUpload = Array.from(e.target.files); //  e.target.files;
        console.log("file upload: ", imagesToUpload.length);
        imagesToUpload.map((file) => { console.log(file.name) });

        this.setState({ imagesToUpload });
        FileUploaderSpinner.uploadFiles(imagesToUpload, this.state.lotData, this.addImage, this.handleUploadCompletion, this.handleUploadCancel);

        //this.setState({ selectedFiles, currentFileIndex });
    }

    handleItemDeleteConfirm = () => {

        const imageIds = this.getSelectedIds();
        console.log("Deleteting images with ids:", imageIds);
        deleteImages(imageIds, this.handleDeletedImagesMessage, this.handleErrorMessage);
    }

    getSelectedIds = () => {
        const idsToDelete = [];
        this.state.images.map((image, index) => {

            if (image.checkboxChecked) {
                console.log("image ind: " + index + " image: ", image);
                idsToDelete.push(image.id);
            }
        });
        return idsToDelete;
    }

    handleImageDeleteClick = () => {
        console.log("Delete button clicked...");
        //handleErrorMessage

        const idsToDelete = this.getSelectedIds();

        console.log("idsToDelete: ", idsToDelete);

        if (idsToDelete.length <= 0) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">No items are selected to be deleted.</span>);
        } else {
            const dialogConfirm = {
                showConfirmDialog: true,
                title: "Confirm Image(s) Delete",
                message: "Do you want to delete  selected images",
                //handleClose: this.handleConfirmDialogClose,
                proceedCallBack: this.handleItemDeleteConfirm,
                proceedButtonLabel: "Confirm Delete"
            }
            ConfirmDialog.showConfirmDlg(dialogConfirm);
        }


    }

    handleDeletedImagesMessage = (data) => {
        console.log("handleDeletedImagesMessage:", data);

        // this.setState({
        //     images: null,
        //     imagesToUpload: null,
        //     lotData: null
        // });

        this.initialize();
    }


    render() {
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <center><h3 className={"my-3"}>Image Upload for  {this.state.lotData ? this.state.lotData.lotNumber : " Not provided. "} </h3></center>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <Form>
                            <Form.File
                                id="custom-file"
                                label="Select Images to Upload"
                                accept="image/png, image/jpeg"
                                onChange={this.handleFileSelection}
                                ref={(ref) => this.fileUpload = ref}
                                multiple={true}
                                capture={"environment"}
                                custom
                            />

                        </Form>
                    </div>
                    <div className="col-md-auto">
                        <Button onClick={this.handleImageDeleteClick} variant="danger" className="ml-2"> Delete Items </Button>
                    </div>
                    <div className="col-md-auto">
                        {this.state.lotData && <NavLink className="nav-link ml-2" to={`${process.env.PUBLIC_URL}/lotedit/${this.state.lotData.id}/`} >Back to lot edit</NavLink>}
                    </div>

                </div>

                <div className="row justify-content-md-center">
                    <div className="col-md-auto mb-3">
                        <FileUploaderSpinner
                        // imagesToUpload={this.state.imagesToUpload}
                        // lotData={this.state.lotData}
                        // onCompletion={this.handleUploadCompletion}
                        // onCancel={this.handleUploadCancel}
                        />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <ImageTable items={this.state.images} />
                    </div>
                </div>
                <div id="divLargerImage"></div>
                <div id="divOverlay"></div>
            </div>
        );
    }
}

export default LotImageManager;