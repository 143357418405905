import React, { Component } from 'react';
import { Nav, Button } from "react-bootstrap";
import NavBar from './NavBar';
import { NavLink } from "react-router-dom";
import { ExternalLink } from 'react-external-link';
import { getManifests, requestBoxes, deleteBoxesById, createBoxRequest } from '../services/websockets'
import NewBoxDialog from './NewBoxDialog';
import BoxEdit from './BoxEdit';
import ConfirmDialog from './common/ConfirmDialog';
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import LotSummary from './LotSummary';


class LotEdit extends Component {

    state = {
        lot: null,
        boxes: null,
        selectedBoxId: null
    }

    componentDidMount() {
        this.initialize();
    }

    initialize = () => {

        const lot = null
        const boxes = null;
        const selectedBoxId = null;
        this.setState({ lot, boxes, selectedBoxId });

        if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            const lotId = id;
            NavBar.showMessage(<span className="badge badge-primary">Fetching Lot data...</span>);
            console.log("LotEdit componentDidMount, feting lot data for lot id:", lotId);
            //const statusFilters = [];
            const statusFilters = ["NOT_LISTED", "LISTED", "SOLD"];
            getManifests(statusFilters, lotId, -1, this.handleGetManifestMessage, this.handleErrorMessage);
            requestBoxes(lotId, -1, this.handleBoxesMessage, this.handleErrorMessage)

        } else {
            console.log("id is not set!");
        }
    }

    handleBoxesMessage = (data) => {
        const boxes = data[0];
        console.log("xxxx handleBoxesMessage: ", boxes);
        let { selectedBoxId } = this.state;
        if (boxes && boxes.length > 0) {
            selectedBoxId = boxes[0].id;
        }
        this.setState({ boxes, selectedBoxId });
        NavBar.showMessage(<span className="badge badge-success">Boxes Loaded...</span>);
    }

    handleGetManifestMessage = (data) => {
        console.log("handleGetManifestMessage data: ", data);
        const lot = data[0][0];
        console.log("handleGetManifestMessage: ", lot);
        this.setState({ lot });
        NavBar.showMessage(<span className="badge badge-success">Manifest loaded...</span>);
    }


    handleErrorMessage = (err) => {
        console.log("handleGetManifestErrorMessage: ", err[0]);
        NavBar.showMessage(<span className="badge badge-warning">{err[0]}</span>);
    }



    onSelectTab = (eventKey, event) => {
        console.log("onSelectTab eventKey: ", eventKey);
        console.log("onSelectTab event: ", event);
    }

    handleCreateBoxConfirm = (boxLabel) => {


        console.log("handleCreateBoxConfirm() boxLabel: ", boxLabel);
        console.log("handleCreateBoxConfirm() this.state.lot.id: ", this.state.lot.id);

        createBoxRequest(boxLabel, this.state.lot.id, this.createdBoxMessageCallback, this.errorMessageProcess);
    }

    errorMessageProcess = () => {
        console.log("errorMessageProcess");
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">Error creating box.</span>);
    }



    createdBoxMessageCallback = (data) => {
        console.log("createdBoxMessageCallback: ", data);
        const createdBox = data[0][0];
        console.log("createdBoxMessageCallback : ", createdBox);
        // reload all boxes
        requestBoxes(this.state.lot.id, -1, this.handleBoxesMessage, this.handleErrorMessage)
        console.log("this.state.createdBox : ", this.state.createdBox);
        NavBar.showMessage(<span className="badge badge-success">Box successfully created {createdBox.label ? "Label: " + createdBox.label : ""} {createdBox.id ? " , id: " + createdBox.id : ""}.</span>);

    }



    handleCreateBoxClick = () => {
        console.log("handleCreateBoxClick");
        //        NewBoxDialog.showConfirmDlg();

        const dialogConfirm = {
            showConfirmDialog: true,
            title: "New Box",
            message: "Enter Box Label",
            // handleClose: this.handleConfirmDialogClose,
            proceedCallBack: this.handleCreateBoxConfirm,
            proceedButtonLabel: "Create",
            inputBox: true,
            inputPlaceHolder: "Box label"
        };
        ConfirmDialog.showConfirmDlg(dialogConfirm);


    }


    processCreatedBox = (newBox) => {
        console.log("processCreatedBox... newBox: ", newBox);
        //getManifests(statusFilters, lotId, this.handleGetManifestMessage, this.handleErrorMessage);
        requestBoxes(this.state.lot.id, -1, this.handleBoxesMessage, this.handleErrorMessage)
    }

    handleTabClick = (key) => {
        console.log("handleTabClick:", key);
        const selectedBoxId = Number(key);
        console.log("handleTabClick selectedBoxId:", selectedBoxId);
        this.setState({ selectedBoxId });
    }

    handleConfirmBoxDeleteConfirm = () => {
        console.log("handleConfirmBoxDeleteConfirm, deleting: ", this.state.selectedBoxId);
        const idsToDelete = [this.state.selectedBoxId];
        deleteBoxesById(idsToDelete, this.handleDeletedBoxesMessage, this.handleDeletedBoxesErrorMessage);
    }

    handleDeletedBoxesMessage = (data) => {
        console.log("handleDeletedBoxesMessage: ", data[0]);

        playFound();
        NavBar.showMessage(<span className="badge badge-success">Deleted box. </span>);
        this.initialize();
    }


    handleDeletedBoxesErrorMessage = (data) => {
        console.log("handleDeletedBoxesErrorMessage: ", data[0]);
    }


    handleDeleteBoxClick = () => {
        console.log("handleDeleteBoxClick....");

        if (!this.state.selectedBoxId) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">Please select box to delete.</span>);
        } else {

            const dialogConfirm = {
                showConfirmDialog: true,
                title: "Confirm Box Delete",
                message: "Do you want to delete box with id: " + this.state.selectedBoxId,
                //handleClose: this.handleConfirmDialogClose,
                proceedCallBack: this.handleConfirmBoxDeleteConfirm,
                proceedButtonLabel: "Delete"
            }
            ConfirmDialog.showConfirmDlg(dialogConfirm);
        }

    }

    renderBoxTabs = () => {

        console.log("zxxxx this.props.userInfo:", this.props.userInfo);

        if (this.state.boxes) {
            return (
                <Nav fill onSelect={this.handleTabClick} key={"boxtabsxx"} variant="tabs" defaultActiveKey={this.state.selectedBoxId}>
                    {this.state.boxes && this.state.boxes.length > 0 &&
                        this.state.boxes.map((box, key) => (
                            <Nav.Item key={"boxtab" + box.id}>
                                <Nav.Link eventKey={box.id}>{"box " + (box.label ? box.label : box.id)}</Nav.Link>
                            </Nav.Item>
                        ))
                    }
                    <Nav.Item key={"boxtabNewBox"}>
                        <Button variant="primary" className={"ml-2"} onClick={this.handleCreateBoxClick} size="sm" >Add Box</Button>
                    </Nav.Item>
                    <Nav.Item key={"boxtabDeleteBox"}>
                        <Button variant="danger" className={"ml-2"} onClick={this.handleDeleteBoxClick} size="sm" >Delete Box</Button>
                    </Nav.Item>
                    <Nav.Item key={"boxtabEditImagesBox"}>
                        <NavLink className="nav-link ml-2" to={`${process.env.PUBLIC_URL}/lotimagemanager/${this.state.lot.id}/`} >Add/Remove images</NavLink>
                    </Nav.Item>

                    {
                        this.props.userInfo.isAdmin && this.state.lot &&
                        <Nav.Item key={"list_link"}>
                            {/* https://localhost/LotBuilder/users/manifestEdit.html?manifestId= */}
                            {/* <a target="_blank" href={"../manifestEdit.html?manifestId=" + this.state.lot.id}> List Link</a> */}
                            <a target="_blank" href={"https://localhost/LotBuilder/users/manifestEdit.html?manifestId=" + this.state.lot.id}> List Link</a>
                        </Nav.Item>
                    }

                </Nav>
            );
        } else {
            return "";
        }
    }


    render() {
        const { lot, selectedBoxId } = this.state;
        console.log("this.state.selectedBoxId: ", selectedBoxId);
        return (
            <div>
                {this.state.lot && <NewBoxDialog processCreatedBox={this.processCreatedBox} lotId={this.state.lot.id} />}
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {/* <center><h3 className={"mtb-3"}>Lot Edit {lot ? " #: " + lot.lotNumber : ""} </h3></center> */}
                        <LotSummary lotData={lot} />
                    </div>
                </div>



                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {this.renderBoxTabs()}
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {selectedBoxId && <BoxEdit boxId={selectedBoxId} />}
                    </div>
                </div>
            </div>
        );
    }
}

export default LotEdit;