import React, { Component } from 'react';
import { Form } from "react-bootstrap"
import NavBar from './NavBar';
import Spinner from 'react-bootstrap/ProgressBar'
import { UPLOAD_FILE_FOR_MANIFEST, sendRawData } from '../services/websockets'
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"


class ImageUpload extends Component {

    state = {
        userId: null,
        lotId: null,
        lotNumber: null,
        selectedFiles: null,
        currentFileIndex: null,
        statusMessage: null
    }

    componentDidMount = () => {
        this.initialize();
    }

    initialize = () => {
        let lotId = null;
        let lotNumber = null;
        if (this.props.match.params.lotId) {
            lotId = this.props.match.params.lotId;
            lotNumber = this.props.match.params.lotNumber;
            NavBar.showMessage(<span className="badge badge-primary">Please select images to upload...</span>);
            console.log("lotId: " + lotId + " lotNumber: " + lotNumber);
            this.setState({ lotId, lotNumber });
        } else {
            console.log("id is not set!");
        }
    }

    handleFileSelection = (e) => {
        const selectedFiles = Array.from(e.target.files); //  e.target.files;
        console.log("file upload: ", selectedFiles.length);
        selectedFiles.map((file) => { console.log(file.name) });

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i]
            // this.sendFile(file);
            console.log(file.name)
        }
        let currentFileIndex = 0;
        if (selectedFiles.length <= 0) {
            currentFileIndex = -1;
        } else {
            console.log("calling UPLOAD_FILE_FOR_MANIFEST() currentFileIndex: ", currentFileIndex);
            UPLOAD_FILE_FOR_MANIFEST(this.state.lotId, this.handleUploadReady, this.handleUploadReadyError);
        }

        this.setState({ selectedFiles, currentFileIndex });
    }

    handleUploadReady = (data) => {
        console.log("handleUploadReady ", data);
        // NavBar.showMessage(<span className="badge badge-success">Uploading file: {this.state.selectedFiles[this.state.currentFileIndex]}</span>);
        this.sendNextFile();
    }

    handleUploadReadyError = (err) => {
        console.log("Error handleUploadReadyError ", err[0])
        NavBar.showMessage(<span className="badge badge-danger">Error uploading file: {this.state.selectedFiles[this.state.currentFileIndex]}</span>);
    }

    sendNextFile = () => {

        var file = this.state.selectedFiles[this.state.currentFileIndex];


        var reader = new FileReader();
        var rawData = new ArrayBuffer();
        reader.loadend = function () {
            console.log("xxxxxxxxxxxx load end");
            /*      const currentFileIndex = this.state.currentFileIndex + 1;
                  if (currentFileIndex == this.state.selectedFiles.length) {
                      console.log("All files uploaded!");
                      NavBar.showMessage(<span className="badge badge-success">All Files uploaded!</span>);
                      playFound();
                      this.setState({ selectedFiles: null, currentFileIndex: null });
                  } else {
                      this.setState({ currentFileIndex });
                      UPLOAD_FILE_FOR_MANIFEST(this.state.lotId, this.handleUploadReady, this.handleUploadReadyError);
                  }
                  */
        }

        reader.onload = function (e) {
            rawData = e.target.result;
            sendRawData(rawData);
            console.log("the File has been transferred.");
        }

        reader.readAsArrayBuffer(file);

    }

    renderProgressBar = () => {
        console.log("renderProgressBar");
        // const progressInstance = <ProgressBar now={this.state.progress} label={`${this.state.progress}%`} />;
        if (this.state.currentFileIndex && this.state.currentFileIndex >= 0) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">{"Uploading file: " + this.state.selectedFiles[this.state.currentFileIndex]}</span>
                </Spinner>
            );
        } else {
            return (
                <span className={"my-3"} >Ready to Upload....</span>
            );
        }
    }

    render() {
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <center><h3 className={"my-3"}>Image Upload for  {this.state.lotNumber ? this.state.lotNumber : " Not provided. "} </h3></center>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <Form>
                            <Form.File
                                id="custom-file"
                                label="Select Images to Upload"
                                accept="image/png, image/jpeg"
                                onChange={this.handleFileSelection}
                                ref={(ref) => this.fileUpload = ref}
                                multiple={true}
                                capture={"environment"}
                                custom
                            />
                        </Form>
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <center>{this.renderProgressBar()}</center>
                    </div>
                </div>





            </div>
        );
    }
}

export default ImageUpload;