import React from "react";

const Input = ({ name, label, error, ...rest }) => {
    //console.log("error:", error);

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <input
                autoFocus
                {...rest}
                id={name}
                name={name}
                className="form-control mb-2 mr-sm-2"
            />
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

export default Input;
