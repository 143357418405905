import React, { Component } from 'react';
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import NavBar from './NavBar'
import { getManifests, createManifestRequest } from '../services/websockets'
import ConfirmDialog from './common/ConfirmDialog';
import LotsTable from './LotsTable';
import { Alert, Button, Form, InputGroup, FormControl } from "react-bootstrap";
import _ from "lodash";

class Lots extends Component {

    state = {
        lots: null,
        lotFilter: {
            LISTED: false,
            SOLD: false,
            NOT_LISTED: true,
            myLots: true
        }
    }

    componentDidMount() {
        // initSounds();
        NavBar.showMessage(<span className="badge badge-primary">Fetching manifests...</span>);
        const userId = this.props.userInfo.userId;
        // const statusFilters = ["NOT_LISTED", "LISTED", "SOLD"];
        const statusFilters = ["NOT_LISTED"];
        // getManifests(statusFilters, -1, -1, this.handleGetManifests, this.handleGetManifestsErrorMessage);
        getManifests(statusFilters, -1, userId, this.handleGetManifests, this.handleGetManifestsErrorMessage);
    }

    handleGetManifests = (data) => {
        const lots = data[0];
        console.log("handleGetManifests: ", lots);
        this.setState({ lots });
        NavBar.showMessage(<span className="badge badge-success">Manifests loaded.</span>);
    }


    handleGetManifestsErrorMessage = (err) => {
        // console.log("handleGetManifestsErrorMessage: ", err[0]);
        // NavBar.showMessage(<span className="badge badge-warning">{err[0]}</span>);
    }

    handleCheckBoxClick = (e) => {
        console.log("handleCheckBoxClick e:", e.target.checked);
        console.log("handleCheckBoxClick e. id:", e.target.id);

        const { lotFilter } = this.state;

        const currentValue = _.get(lotFilter, e.target.id)
        console.log("currentValue: ", currentValue);
        _.set(lotFilter, e.target.id, !currentValue);
        console.log("lotFilter:", lotFilter);

        console.log("userInfo: ", this.props.userInfo.userLogin);

        const statusFilters = []; // ["NOT_LISTED", "LISTED", "SOLD"];
        if (lotFilter.NOT_LISTED) {
            statusFilters.push("NOT_LISTED");
        }
        if (lotFilter.LISTED) {
            statusFilters.push("LISTED");
        }
        if (lotFilter.SOLD) {
            statusFilters.push("SOLD");
        }

        let userId = this.props.userInfo.userId;

        if (!lotFilter.myLots) {
            userId = -1;
        }

        console.log("userId: ", userId);
        console.log("statusFilters: ", statusFilters);



        this.setState({ lotFilter });
        getManifests(statusFilters, -1, userId, this.handleGetManifests, this.handleGetManifestsErrorMessage);

    }

    renderButtons = () => {
        return (


            <InputGroup className="my-2">
                <Button onClick={this.handleCreateLotClick} variant="success" className="ml-2" > Create Lot </Button >
                <Form.Check onChange={this.handleCheckBoxClick} checked={this.state.lotFilter.NOT_LISTED} className={"ml-2 my-2"} value={'true'} type={"checkbox"} id={"NOT_LISTED"} label={"NOT_LISTED"} />
                <Form.Check onChange={this.handleCheckBoxClick} checked={this.state.lotFilter.LISTED} className={"ml-2 my-2"} type={"checkbox"} id={"LISTED"} label={"LISTED"} />
                <Form.Check onChange={this.handleCheckBoxClick} checked={this.state.lotFilter.SOLD} className={"ml-2 my-2"} type={"checkbox"} id={"SOLD"} label={"SOLD"} />
                <Form.Check onChange={this.handleCheckBoxClick} disabled={!this.props.userInfo.isAdmin} checked={this.state.lotFilter.myLots} className={"ml-2 my-2"} type={"checkbox"} id={"myLots"} label={"My Lots"} />
            </InputGroup>



        );
    }


    handleMenifetCreatedMessage = (data) => {
        const createdLot = data[0][0];
        const { lots } = this.state;
        lots.push(createdLot);
        console.log("Lots.handleMenifetCreatedMessage: ", createdLot);
        playFound();
        NavBar.showMessage(<span className="badge badge-success">{"Created lot id:" + createdLot.id + " lot # " + createdLot.lotNumber + " Label: " + createdLot.titleListing}</span>);
        this.setState({ lots });
    }

    handleMenifetCreatedMessageError = (data) => {
        console.log("Lots.handleMenifetCreatedMessageError: ", data[0]);
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">{"Error creating lots:" + data[0]}</span>);
    }


    handleConfirmDialogClose = () => {
        console.log("Lots.handleConfirmDialogClose cancel creating new lot callback....")
    }

    handleCreateLotConfirm = (label) => {
        console.log("Lots.handleCreateLotConfirm label:", label);
        createManifestRequest(label, this.handleMenifetCreatedMessage, this.handleMenifetCreatedMessageError);

    }

    handleCreateLotClick = () => {
        console.log("handleCreateLotClick");
        const dialogConfirm = {
            showConfirmDialog: true,
            title: "New Lot",
            message: "Enter Lot Label",
            // handleClose: this.handleConfirmDialogClose,
            proceedCallBack: this.handleCreateLotConfirm,
            proceedButtonLabel: "Create",
            inputBox: true,
            inputPlaceHolder: "Lot label"
        };
        ConfirmDialog.showConfirmDlg(dialogConfirm);
    }


    render() {
        return (
            <div className="container-fluid">
                <center><h4>Lots ({this.state.lots && this.state.lots.length}) </h4> </center>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {this.renderButtons()}
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-auto">
                            <LotsTable lots={this.state.lots} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Lots;