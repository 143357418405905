import React from 'react';
import Table from './common/Table';
import CheckboxSelect from './common/CheckboxSelect';

const ItemsTable = (props) => {

    let columns = [
        { path: "UPC", label: "UPC", contentEditable: false, isNumber: true },
        { path: "quantity", label: "Qnty", contentEditable: true, isNumber: true },
        { path: "retailPrice", label: "Msrp", contentEditable: true, isNumber: true },
        { path: "clientCost", label: "Your Cost", contentEditable: true, isNumber: true },

        { path: "listingPrice", label: "List Price", contentEditable: true, isNumber: true },
        { path: "vendor", label: "Vendor", contentEditable: true, isNumber: false },
        { path: "itemDescriptionManifest", label: "Desc", contentEditable: true, isNumber: false },
        { path: "color", label: "Color", contentEditable: true, isNumber: false },
        { path: "size", label: "Size", contentEditable: true, isNumber: false },
        {
            key: "ckeckbox",
            label: "Select",
            content: item => (
                <CheckboxSelect key={item.id + "CB"} item={item} checkboxClick={() => props.checkboxClick(item)} />
            )
        }
    ];


    return (
        <div>
            <Table columns={columns} items={props.items} />
        </div>
    );
}

export default ItemsTable;