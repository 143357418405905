import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NavBar extends Component {
    state = {
        message: null
    };

    static setMessageMethod = null;

    static showMessage(message) {
        if (NavBar.setMessageMethod) {
            NavBar.setMessageMethod(message);
        }
    }

    componentDidMount() {
        NavBar.setMessageMethod = this.setMessage;
    }

    setMessage = (message) => {
        this.setState({ message });
    }


    render() {

        // console.log("this.props:", this.props);
        const userInfo = this.props.user;
        // console.log("userInfo:", userInfo);
        return (
            //   <nav className="navbar navbar-expand-lg navbar-light bg-light">

            <nav className="navbar navbar-expand navbar-light bg-light">
                <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/lots`}>
                    {userInfo.userLogin} welcome to Lot Editor
                </NavLink>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/lots`} >
                                Lots
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/boxes`} >
                                Boxes
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/newbox`} >
                                New Box
                            </NavLink>
                        </li> */}

                        {this.state.message && <li className="nav-item">
                            <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/newbox`}>
                                {this.state.message}
                            </NavLink>
                        </li>}

                    </ul>
                </div>
            </nav>

        );
    }
}

export default NavBar;