import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Table from './common/Table';
import CheckboxSelect from './common/CheckboxSelect';


class LotsTable extends Component {

    columns = [
        { path: "lotNumber", label: "LOT #", contentEditable: false, isNumber: false },
        { path: "status", label: "STATUS", contentEditable: false, isNumber: false },
        { path: "titleListing", label: "TITLE", contentEditable: false, isNumber: false },
        { path: "category", label: "CAT", contentEditable: false, isNumber: false },

        { path: "returnType", label: "RET TYPE", contentEditable: false, isNumber: false, },
        { path: "numberOfCartons", label: "CARTONS", contentEditable: false, isNumber: false, },
        { path: "numberOfPallets", label: "PALLETS", contentEditable: false, isNumber: false, },
        { path: "numberOfUnits", label: "UNITS", contentEditable: false, isNumber: false, },
        { path: "totalListingPrice", label: "LISTING", contentEditable: false, isNumber: false, },
        { path: "totalOriginalRetail", label: "RETAIL", contentEditable: false, isNumber: false, },
        {
            path: "edit",
            label: "Edit",
            content: lot => <Link to={`${process.env.PUBLIC_URL}/lotedit/${lot.id}`}>Edit</Link>
        }

        // {
        //     key: "ckeckbox",
        //     label: "Select",
        //     content: item => (
        //         <CheckboxSelect key={item.id + "CB"} item={item} checkboxClick={() => props.checkboxClick(item)} />
        //     )
        // }
    ];

    //"NOT_LISTED", "LISTED", "SOLD"
    getRowStyle = (item) => {

        if (item.status) {
            if (item.status == "NOT_LISTED") {

            } else if (item.status == "LISTED") {

            } else if (item.status == "SOLD") {

            }
        }

    }

    render() {
        return (<Table columns={this.columns} items={this.props.lots} />);
    }
}

export default LotsTable;



