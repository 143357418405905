import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from "./common/Input"
import { createBoxRequest } from '../services/websockets'

class NewBoxForm extends Component {

    state = {
        title: "",
        createdBox: null
    }

    handleChange = ({ currentTarget: input }) => {
        console.log("handleChange:", input.value);
        //const title = this.state.title;
        const title = input.value;
        this.setState({ title });
    };

    renderInput(name, label, type = "text") {
        const { title } = this.state;
        return (
            <Input
                name={name}
                label={label}
                value={title}
                onChange={this.handleChange}
                // error={errors[name]}
                type={type}
            />
        );
    }

    renderButton = label => {
        return (
            <button
                disabled={!this.validate()}
                className="btn btn-primary">
                {label}
            </button>
        );
    }

    createdItemCallback = (createdItem) => {
        console.log("createdItem: ", createdItem);
        const createdBox = createdItem[0][0];
        console.log("createdBoxxxx : ", createdBox);
        const title = "";
        this.setState({ title, createdBox });

        console.log("this.state.createdBox : ", this.state.createdBox);
    }

    validate = () => {
        if (this.state.title.trim() === "") {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.validate()) {
            console.log("handleSubmit() creating new, titale: ", this.state.title);
            createBoxRequest(this.state.title, this.createdItemCallback);
        }
    }


    renderCreatedItem = () => {
        const createdBox = this.state.createdBox;
        console.log("rendorCreatedItem: ", createdBox);

        if (createdBox) {
            return (
                <React.Fragment>
                    <br />
                    <h1>Created Item</h1>
                    <span>Item label: </span> {createdBox.label} <span> id: </span>{createdBox.id}
                    <Link
                        to={`${process.env.PUBLIC_URL}/boxedit/` + createdBox.id}
                        //  className="btn btn-primary"
                        style={{ marginLeft: 20 }}
                    >
                        Edit
                    </Link>
                    <br />
                </React.Fragment>
            );
        }
    }

    render() {

        return (
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {this.renderCreatedItem()}
                        <h1 style={{ marginTop: 50 }}>Create New Box</h1>
                        <form onSubmit={this.handleSubmit}>
                            {this.renderInput("title", "Title")}  {this.renderButton("Create")}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewBoxForm;