import React, { Component } from 'react';
import {
    getBoxAndItems, addItemByUPC, updateItems, deleteItemsById
} from '../services/websockets'

import ItemsTable from './ItemsTable';
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import Popup from './common/Popup';
import { Alert, Button, Badge } from "react-bootstrap";
import NavBar from './NavBar';
import UPCSearchBox from './UPCSearchBox';
import ConfirmDialog from './common/ConfirmDialog';
import UpdateBoxWeightDims from './UpdateBoxWeightDims';


class BoxEdit extends Component {

    state = {
        boxData: null,
        items: null,
    }


    constructor() {
        super();
        //initSounds();
        this.myRef = React.createRef();
        NavBar.showMessage(<span className="badge badge-primary">Fetching box data...</span>);
    }

    UPCEntry = "";
    componentDidMount() {
        console.log("BoxEdit.componentDidMount()  this.props.boxId: ", this.props.boxId);
        this.initialize();
    }

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps next props: ", nextProps);
        if (nextProps.boxId && nextProps.boxId != this.props.boxId) {

            this.requestDataByBoxId(nextProps.boxId);
        }
    }

    initialize = () => {
        console.log("BoxEdit.initialize()  this.props.boxId: ", this.props.boxId);

        let boxId = 0;
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            console.log("BoxEdit.initialize() using params this.props.match.params.id: " + this.props.match.params.id);
            boxId = this.props.match.params.id
        } else if (this.props.boxId) {
            console.log("BoxEdit.initialize() using props  this.props.boxId: " + this.props.boxId);
            boxId = this.props.boxId;
        }
        this.requestDataByBoxId(boxId);
    }

    requestDataByBoxId = (boxId) => {

        if (boxId > 0) {
            const boxIds = [boxId];
            console.log("BoxEdit componentDidMount, feting data for box id:", boxIds);
            getBoxAndItems(boxIds, this.handleBoxAndItems, this.handleBoxAndItemsError);
        } else {
            console.log("id is not set!");
        }
    }

    handleBoxAndItemsError = data => {
        console.log("handleBoxAndItemsError:", data[0]);
        NavBar.showMessage(<span className="badge badge-danger">{data[0]}</span>);
    }

    handleBoxData = (data) => {
        const boxData = data[0][0];
        console.log("handleBoxData boxData:", boxData);
        this.setState({ boxData });
    }

    handleBoxAndItems = (data) => {
        const boxData = data[0].boxes[0];
        const items = data[0].items;
        NavBar.showMessage(<span className="badge badge-success">Box data is loaded, system ready...</span>);
        this.setState({ boxData, items });
    }

    handleItemAddError = (data) => {
        console.log("handleItemAddError data: ", data)
        this.myRef.current.value = "";
        this.UPCEntry = "";
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">{data}</span>);
    }

    handleItemAdd = (data) => {
        const item = data[0][0];
        console.log("handleItemAdd data: ", item)

        console.log("searching for UPC: ", item.UPC);
        let ind = -1;
        this.state.items.map((itemFromArray, index) => {
            if (itemFromArray.UPC == item.UPC) {
                console.log("MATCH index " + index + " qnt: ", itemFromArray.quantity + " will be replaced with qnty: " + item.quantity);
                ind = index;
            } else {
                console.log("NO MATCH index ", index);
            }
        }
        );

        this.myRef.current.value = "";
        console.log("Found index: ", ind);
        const { items } = this.state;
        if (ind >= 0) {
            items[ind] = item;
            playFoundExisting();
            NavBar.showMessage(<span className="badge badge-success">UPC {item.UPC} incremented to {item.quantity}</span>);
            this.setState({ items });

        } else {
            items.push(item);
            playFound();
            NavBar.showMessage(<span className="badge badge-success">UPC {item.UPC} added.</span>);
            this.setState({ items });
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        console.log("handleSubmit UPCEntry: ", this.UPCEntry);
        const boxIds = [this.state.boxData.id];
        addItemByUPC(this.UPCEntry, boxIds, this.handleItemAdd, this.handleItemAddError);
    }

    handleUPCChange = ({ currentTarget: input }) => {
        this.UPCEntry = input.value;
        console.log("handleUPCChange:", this.UPCEntry);
    }

    handleUpdatedItems = (data) => {
        const { items } = this.state;
        const updatedItems = data[0];

        updatedItems.map((item, index) => {
            items.map((stateItem, stateIndex) => {
                if (item.id == stateItem.id) {
                    items[stateIndex] = item;
                }
            })
        });

        console.log("handleUpdatedItems: ", items);

        // const statusMessage = <Alert key="alertsuccess" variant="success">Updated {updatedItems.length} Items.</Alert>;
        playFound();
        // this.setState({ items, statusMessage });
        NavBar.showMessage(<span className="badge badge-success">Updated {updatedItems.length} Item(s).</span>);
        this.setState({ items });
    }

    handleUpdateItemsError = (data) => {
        console.log("handleUpdateItemsError: ", data);
        playNotFound();
        // const statusMessage = <Alert key="alertdanger" variant="danger">Error updating items: {data}</Alert>;        
        // this.setState({ statusMessage });
        NavBar.showMessage(<span className="badge badge-danger">Error updating items: {data}</span>);
    }

    handleItemUpdateClick = () => {
        console.log("handleUpdate() ");

        const itemsToUpdate = this.state.items.filter(item => item.changed ? item.changed : false);
        console.log("itemsToUpdate: ", itemsToUpdate);

        if (itemsToUpdate.length <= 0) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">Warning did not find any modified data to update.</span>);
        } else {
            updateItems(itemsToUpdate, this.handleUpdatedItems, this.handleUpdateItemsError);
        }
    }

    handleDeletedItemsMessage = (data) => {
        console.log("handleDeletedItemsReply", data);
        const arrayOfDeletedIds = data[0];
        console.log("BEFORE this.state.items", this.state.items);
        const items = this.state.items.filter((item) => {
            let itemDeleted = false;
            arrayOfDeletedIds.map((deletedId) => {
                if (deletedId == item.id) {
                    itemDeleted = true;
                }
            });
            return !itemDeleted;
        });

        console.log("AFTER items: ", items);
        playFound();
        NavBar.showMessage(<span className="badge badge-success">Deleted {arrayOfDeletedIds.index} items. </span>);
        this.setState({ items });
    }

    handleDeletedItemsErrorMessage = (data) => {
        console.log("handleDeletedItemsReply", data[0]);
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">Failed to delete: {data[0]}</span>);
    }

    handleItemDeleteConfirm = () => {
        console.log("handleItemDeleteConfirm() ");

        const idsToDelete = [];
        this.state.items.map((item, index) => {

            if (item.checkboxChecked) {
                console.log("item ind: " + index + " item: ", item);
                idsToDelete.push(item.id);
            }
        });

        console.log("idsToDelete: ", idsToDelete);

        if (idsToDelete.length <= 0) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">Warning, please saelect items to delete.</span>);
        } else {
            deleteItemsById(idsToDelete, this.handleDeletedItemsMessage, this.handleDeletedItemsErrorMessage);
        }
        //this.handleConfirmDialogClose();
    }

    handleItemDeleteClick = () => {
        console.log("handleItemDeleteClick() ");

        const idsToDelete = [];
        const upcsToDelete = [];
        this.state.items.map((item, index) => {

            if (item.checkboxChecked) {
                console.log("item ind: " + index + " item: ", item);
                idsToDelete.push(item.id);
                upcsToDelete.push(item.UPC);
            }
        });

        console.log("idsToDelete: ", idsToDelete);

        if (idsToDelete.length <= 0) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">No items are selected to be deleted.</span>);
        } else {
            const dialogConfirm = {
                showConfirmDialog: true,
                title: "Confirm Item(s) Delete",
                message: "Do you want to delete items with id(s): " + upcsToDelete,
                //handleClose: this.handleConfirmDialogClose,
                proceedCallBack: this.handleItemDeleteConfirm,
                proceedButtonLabel: "Confirm Delete"
            }
            ConfirmDialog.showConfirmDlg(dialogConfirm);
        }
    }

    updateWeightDimsClick = () => {
        console.log("updateWeightDimsClick:");
        UpdateBoxWeightDims.showUpdateBoxWeightDims(this.state.boxData, this.handleBoxData, this.handleBoxAndItemsError);
    }

    renderUPCsearchBox() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="input-group mb-3">
                        <input ref={this.myRef} autoFocus type="text" className="form-control" placeholder="UPC code..." onChange={this.handleUPCChange} />
                        <div className="input-group-append">
                            <input className="btn btn-primary ml-2" type="submit" value="Add"></input>
                            <Button onClick={this.handleItemUpdateClick} variant="warning" className="ml-2"> Update Items </Button>
                            <Button onClick={this.handleItemDeleteClick} variant="danger" className="ml-2"> Delete Items </Button>
                            <Button onClick={this.updateWeightDimsClick} variant="success" className="ml-2"> Update Weight/Dims </Button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }

    getTotalItems = () => {
        let count = 0;
        let summary = {
            count: 0,
            retail: 0.0
        };


        if (this.state.items) {
            this.state.items.map((item) => { summary.count += item.quantity; summary.retail += (item.retailPrice * item.quantity) });
        }
        return (<React.Fragment> <Badge variant="primary">ITEMS: {summary.count}</Badge> Retail: <Badge variant="primary">MSRP: ${summary.retail}</Badge> </React.Fragment>);
    }

    renderBoxData = () => {
        const { boxData } = this.state;
        if (boxData) {
            return (<h6 className={"mtb-3"}>
                Box Edit id: { boxData.id} Label: {boxData.label} Dims: {boxData.H}  x {boxData.W} x {boxData.L} Weight: {boxData.Lbs} lbs {boxData.Ozs} Ozs Total {this.getTotalItems()}</h6>);
        } else {
            return (<h6>Box Data Loading...</h6>);
        }

    }

    handlePopupClose = (showPopup) => {
        console.log("handlePopupClose: ", showPopup);
        this.setState({ showPopup });
    }

    render() {
        const { items } = this.state;
        return (
            <div className="container">
                {this.state.showPopup && <Popup title={"Popup title"} message={"Popup message"} showPopup={this.state.showPopup} handlePopupClose={this.handlePopupClose} />}
                <div className="row justify-content-md-center my-2">
                    <div className="col-md-auto">
                        {this.renderBoxData()}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {this.renderUPCsearchBox()}
                        {/* <UPCSearchBox items={items} /> */}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <ItemsTable items={items} />
                    </div>
                </div>
                <UpdateBoxWeightDims />
            </div>

        );
    }
}

export default BoxEdit;