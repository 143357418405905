import React, { Component } from 'react';
import { Button, Modal, Form, InputGroup, Col, FormControl } from "react-bootstrap"
import {
    createBoxRequest, updateBoxData
} from '../services/websockets'
import NavBar from './NavBar';
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"

class UpdateBoxWeightDims extends Component {

    static = { self: null }

    state = {
        // boxInfo: null,
        validated: false,
        show: false
    }

    static showUpdateBoxWeightDims(boxInfo, handleBoxData, handleBoxAndItemsError) {
        if (UpdateBoxWeightDims.self) {
            UpdateBoxWeightDims.self.setState({ show: true });
            UpdateBoxWeightDims.self.boxInfo = boxInfo;
            UpdateBoxWeightDims.self.handleBoxData = handleBoxData;
            UpdateBoxWeightDims.self.handleBoxAndItemsError = handleBoxAndItemsError;
        }
    }

    boxInfo = null;
    handleBoxData = null;
    handleBoxAndItemsError = null;

    componentDidMount() { UpdateBoxWeightDims.self = this; }

    handleClose = () => {
        this.setState({ show: false });
    }


    handleSubmit = (event) => {
        console.log("handleSubmit:")
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        console.log("form:", form);

        if (form.checkValidity() === false) {
            console.log("invalid input");
            console.log("boxInfo: ", this.boxInfo);
        } else {
            console.log("valid input...");
            console.log("boxInfo: ", this.boxInfo);
            this.setState({ validated: true, show: false });
            const boxData = [this.boxInfo];
            updateBoxData(boxData, this.handleBoxData, this.handleBoxAndItemsError);
        }
    }



    render() {
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Box Weight and Dimensions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Height</Form.Label>
                                        <Form.Control
                                            required
                                            defaultValue={this.boxInfo && this.boxInfo.H ? this.boxInfo.H : 0}
                                            type="number"
                                            placeholder="inches.."
                                            onChange={e => this.boxInfo.H = Number(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Width</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            defaultValue={this.boxInfo && this.boxInfo.W ? this.boxInfo.W : 0}
                                            placeholder="inches.."
                                            onChange={e => this.boxInfo.W = Number(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                        <Form.Label>Length</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                defaultValue={this.boxInfo && this.boxInfo.L ? this.boxInfo.L : 0}
                                                placeholder="inches"
                                                required
                                                onChange={e => this.boxInfo.L = Number(e.target.value)}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                                        <Form.Label>Lbs</Form.Label>
                                        <Form.Control type="number" placeholder="lbs.." required
                                            defaultValue={this.boxInfo && this.boxInfo.Lbs ? this.boxInfo.Lbs : 0}
                                            onChange={e => this.boxInfo.Lbs = Number(e.target.value)}
                                        />

                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                                        <Form.Label>Ozs</Form.Label>
                                        <Form.Control type="number" placeholder="ozs..." required
                                            defaultValue={this.boxInfo && this.boxInfo.Ozs ? this.boxInfo.Ozs : 0}
                                            onChange={e => this.boxInfo.Ozs = Number(e.target.value)}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Form.Row>
                                <Button type="submit">Submit form</Button>
                            </Form>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>);
    }
}

export default UpdateBoxWeightDims;

