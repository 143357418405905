import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { initWebsockets } from './services/websockets'
import NavBar from './components/NavBar';
import Lots from './components/Lots';
import Boxes from './components/Boxes'
import BoxEdit from './components/BoxEdit'
import NotFound from './components/NotFound';
import NewBoxForm from './components/NewBoxForm';
import ConnectingWarning from './components/ConnectingWarning';
import LotEdit from './components/LotEdit';
import ConfirmDialog from './components/common/ConfirmDialog';
import { initSounds } from "./services/sounds"
import ImageUpload from './components/ImageUpload';
import LotImageManager from './components/LotImageManager';


class App extends Component {
  state = {
    userInfo: { userId: -1, userLogin: "", isAdmin: false }
  }

  componentDidMount() {
    initWebsockets(this.handleUserInfo);
    initSounds();
  }

  handleUserInfo = (args) => {
    console.log("args:", args);
    const userInfo = args[0];
    console.log("handleUserInfo: ", userInfo);
    this.setState({ userInfo });
  }



  render() {

    console.log("REACT_APP_WS_URL:", process.env.REACT_APP_WS);
    const { userInfo } = this.state;
    return (
      <div>
        <ToastContainer />
        <ConfirmDialog />

        {userInfo.userId > 0 &&
          (<React.Fragment>

            <NavBar user={userInfo} />
            <div className="content"></div>
            <Switch>



              <Route
                // path={"`${process.env.PUBLIC_URL}/boxes"}
                path={`${process.env.PUBLIC_URL}/boxes`}
                render={props => <Boxes {...props} userInfo={userInfo} />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lots`}
                render={props => <Lots {...props} userInfo={userInfo} />}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/newbox`}
                render={props => <NewBoxForm {...props} userInfo={userInfo} />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/boxedit/:id`}
                render={props => <BoxEdit {...props} userInfo={userInfo} />}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/imageupload/:lotId/:lotNumber`}
                render={props => <ImageUpload {...props} userInfo={userInfo} />}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/lotimagemanager/:lotId`}
                render={props => <LotImageManager {...props} userInfo={userInfo} />}
              />


              <Route
                path={`${process.env.PUBLIC_URL}/lotedit/:id`}
                render={props => <LotEdit {...props} userInfo={userInfo} />}
              />

              <Route path={`${process.env.PUBLIC_URL}/not-found`} component={NotFound} />
              <Redirect from={`${process.env.PUBLIC_URL}/`} exact to={`${process.env.PUBLIC_URL}/lots`} />
              <Redirect from={`${process.env.PUBLIC_URL}/index.html`} exact to={`${process.env.PUBLIC_URL}/lots`} />
              <Redirect exact from="/" to="searchDashboard" />
              <Redirect to={`${process.env.PUBLIC_URL}/not-found`} />
            </Switch>
          </React.Fragment>
          )}
        {userInfo.userId < 0 && (<ConnectingWarning />)}
      </div>



    );

  }
}

export default App;


