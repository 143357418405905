import React, { Component } from "react";
import TableCell from './TableCell';

class TableBody extends Component {

    createKey = (item, column) => {
        return item.id + (column.label || column.key);
    };

    renderCell(item, column, index) {
        if (column.content) {
            return <td key={item.id + index + "tdcb"}>{column.content(item)} </td>
        } else {
            return <TableCell key={item.id + index + "tc"} item={item} column={column} />
        }
    };

    renderTableRows = () => {
        const { items, columns } = this.props;
        let counter = 1;
        return (
            items && items.map(item => (

                <tr key={item.id}>
                    <td>{counter++}</td>
                    {columns.map((column, index) => (
                        this.renderCell(item, column, index)
                        //<TableCell key={item.id + index + "tc"} item={item} column={column} />
                    ))}
                </tr>
            ))

        );

    }

    render() {
        return (
            <tbody>
                {this.renderTableRows()}
            </tbody>
        );
    }
}

export default TableBody;
