import React, { Component } from 'react';
import { Alert, Button } from "react-bootstrap";
import {
    requestBoxes, deleteBoxesById
} from '../services/websockets'
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import BoxesTable from './BoxesTable';
import NavBar from './NavBar';
import ConfirmDialog from './common/ConfirmDialog';
import NewBoxDialog from './NewBoxDialog';


class Boxes extends Component {
    state = {
        boxes: null
    }

    componentDidMount() {
        //initSounds();
        NavBar.showMessage(<span className="badge badge-primary">Fetching boxes...</span>);
        requestBoxes(-1, -1, this.handleBoxesMessage, this.handleErrorMessage);
    }

    handleBoxesMessage = (data) => {
        const boxes = data[0];
        console.log("handleBoxesMessage: ", boxes);
        this.setState({ boxes });
        NavBar.showMessage(<span className="badge badge-success">Boxes loaded.</span>);
    }


    handleErrorMessage = (err) => {
        console.log("handleErrorMessage: ", err[0]);
        NavBar.showMessage(<span className="badge badge-warning">{err[0]}</span>);
    }

    handleItemUpdateClick = () => {
        console.log("handleItemUpdateClick");
    }


    // called from ConfirmDialog
    handleDeleteDialogConfirm = () => {
        console.log("ghandleBoxDeleteClick() ");

        const idsToDelete = [];
        this.state.boxes.map((item, index) => {

            if (item.checkboxChecked) {
                console.log("item ind: " + index + " item: ", item);
                idsToDelete.push(item.id);
            }
        });

        console.log("idsToDelete: ", idsToDelete);

        if (idsToDelete.length <= 0) {
            playNotFound();
            NavBar.showMessage(<span className="badge badge-danger">Warning, please saelect items to delete.</span>);
        } else {
            deleteBoxesById(idsToDelete, this.handleDeletedBoxesMessage, this.handleDeletedBoxesErrorMessage);
        }
    }

    ghandleBoxDeleteClick = () => {

        console.log("ghandleBoxDeleteClick() ");

        const idsToDelete = [];
        this.state.boxes.map((item, index) => {

            if (item.checkboxChecked) {
                console.log("item ind: " + index + " item: ", item);
                idsToDelete.push(item.id);
            }
        });

        console.log("idsToDelete: ", idsToDelete);

        const dialogConfirm = {
            showConfirmDialog: true,
            title: "Confirm Box(es) Delete",
            message: "Are you sure you want to delete box with id(s): " + idsToDelete,
            handleClose: this.handleConfirmDialogClose,
            proceedCallBack: this.handleDeleteDialogConfirm,
            proceedButtonLabel: "Delete/Proceed"
        };
        ConfirmDialog.showConfirmDlg(dialogConfirm);
    }


    handleDeletedBoxesMessage = (data) => {
        console.log("handleDeletedBoxesMessage: ", data[0]);

        const arrayOfDeletedIds = data[0];
        const boxes = this.state.boxes.filter((item) => {
            let itemDeleted = false;
            arrayOfDeletedIds.map((deletedId) => {
                if (deletedId == item.id) {
                    itemDeleted = true;
                }
            });
            return !itemDeleted;
        });

        playFound();
        NavBar.showMessage(<span className="badge badge-success">Deleted {arrayOfDeletedIds.index} items. </span>);
        this.setState({ boxes });
    }


    handleDeletedBoxesErrorMessage = (data) => {
        console.log("handleDeletedBoxesErrorMessage: ", data[0]);
    }


    handleCreateBoxClick = () => {
        console.log("handleCreateBoxClick");
        NewBoxDialog.showConfirmDlg();
    }

    renderButtons = () => {
        return (
            <div className={"mt-3 mb-3"}>
                <Button onClick={this.handleItemUpdateClick} variant="warning" className="ml-2"> Update Boxes </Button>
                <Button onClick={this.ghandleBoxDeleteClick} variant="danger" className="ml-2"> Delete Boxes </Button>
                <Button onClick={this.handleCreateBoxClick} variant="success" className="ml-2"> Create Box </Button>
            </div>
        );
    }

    processCreatedBox = (box) => {
        console.log("processCreatedBox");
        const { boxes } = this.state;
        console.log("XXXXXX box", box);
        console.log("XXXXXX boxes", boxes);
        boxes.push(box);
        this.setState({ boxes });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        {this.renderButtons()}
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-auto">
                        <BoxesTable boxes={this.state.boxes} />
                    </div>
                </div>
                <NewBoxDialog processCreatedBox={this.processCreatedBox} />
                {/* <ConfirmDialog/> */}
                {/* } */}
            </div>);
    }
}

export default Boxes;