import React, { Component } from 'react';

class ConnectingWarning extends Component {
    render() {
        return (
            <center>
                <div>
                    <h3>CONNECTING...</h3>
                </div>
            </center>
        );
    }
}

export default ConnectingWarning;