var foundMp3 = null;
var foundMp3_2 = null;
var notFoundMP3 = null;

export function initSounds() {

    if (foundMp3 == null) {
        foundMp3 = new Audio();
        var src1 = document.createElement("source");
        src1.type = "audio/mpeg";
        src1.src = "https://zip2salesounds.s3.amazonaws.com/found2.mp3";
        console.log('src: ' + src1.src);
        foundMp3.appendChild(src1);


        foundMp3_2 = new Audio();
        var src1_2 = document.createElement("source");
        src1_2.type = "audio/mpeg";
        src1_2.src = "https://zip2salesounds.s3.amazonaws.com/found2.mp3";
        foundMp3_2.appendChild(src1_2);

        notFoundMP3 = new Audio();
        var src2 = document.createElement("source");
        src2.type = "audio/mpeg";
        src2.src = "https://zip2salesounds.s3.amazonaws.com/notfound.mp3";
        notFoundMP3.appendChild(src2);
    }
    console.log("-----------------------------");
}



export function playFound() {
    foundMp3.play();
}

export function playFoundExisting() {
    foundMp3.play();
    setTimeout(function () {
        foundMp3_2.play();
    }, 200);
}

export function playNotFound() {
    notFoundMP3.play();
}

export default { playFound, playFoundExisting, playNotFound, initSounds };