import React, { Component } from 'react';
import { Alert, Button } from "react-bootstrap";

class UPCSearchBox extends Component {
    state = {

    }


    // handleItemDeleteClick = () => {
    //     console.log("handleItemDeleteClick() ");

    //     const idsToDelete = [];
    //     this.state.items.map((item, index) => {

    //         if (item.checkboxChecked) {
    //             console.log("item ind: " + index + " item: ", item);
    //             idsToDelete.push(item.id);
    //         }
    //     });

    //     console.log("idsToDelete: ", idsToDelete);

    //     if (idsToDelete.length <= 0) {
    //         playNotFound();
    //         NavBar.showMessage(<span className="badge badge-danger">Warning, please saelect items to delete.</span>);
    //     } else {
    //         deleteItemsById(idsToDelete, this.handleDeletedItemsMessage, this.handleDeletedItemsErrorMessage);
    //     }
    // }

    // handleDeletedItemsMessage = (data) => {
    //     console.log("handleDeletedItemsReply", data);
    //     const arrayOfDeletedIds = data[0];
    //     console.log("BEFORE this.state.items", this.state.items);
    //     const items = this.state.items.filter((item) => {
    //         let itemDeleted = false;
    //         arrayOfDeletedIds.map((deletedId) => {
    //             if (deletedId == item.id) {
    //                 itemDeleted = true;
    //             }
    //         });
    //         return !itemDeleted;
    //     });

    //     console.log("AFTER items: ", items);
    //     playFound();
    //     NavBar.showMessage(<span className="badge badge-success">Deleted {arrayOfDeletedIds.index} items. </span>);
    //     this.setState({ items });
    // }

    // handleDeletedItemsErrorMessage = (data) => {
    //     console.log("handleDeletedItemsReply", data[0]);
    //     playNotFound();
    //     NavBar.showMessage(<span className="badge badge-danger">Failed to delete: {data[0]}</span>);
    // }


    // handleItemUpdateClick = () => {
    //     console.log("handleUpdate() ");

    //     const itemsToUpdate = this.state.items.filter(item => item.changed ? item.changed : false);
    //     console.log("itemsToUpdate: ", itemsToUpdate);

    //     if (itemsToUpdate.length <= 0) {
    //         playNotFound();
    //         NavBar.showMessage(<span className="badge badge-danger">Warning did not find any modified data to update.</span>);
    //     } else {
    //         updateItems(itemsToUpdate, this.handleUpdatedItems, this.handleUpdateItemsError);
    //     }
    // }

    // handleUpdatedItems = (data) => {
    //     const { items } = this.state;
    //     const updatedItems = data[0];

    //     updatedItems.map((item, index) => {
    //         items.map((stateItem, stateIndex) => {
    //             if (item.id == stateItem.id) {
    //                 items[stateIndex] = item;
    //             }
    //         })
    //     });

    //     console.log("handleUpdatedItems: ", items);

    //     playFound();
    //     NavBar.showMessage(<span className="badge badge-success">Updated {updatedItems.length} Item(s).</span>);
    //     this.setState({ items });
    // }

    // handleUpdateItemsError = (data) => {
    //     console.log("handleUpdateItemsError: ", data);
    //     playNotFound();
    //     NavBar.showMessage(<span className="badge badge-danger">Error updating items: {data}</span>);
    // }


    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <div className="input-group mb-3">
                        <input ref={this.myRef} autoFocus type="text" className="form-control" placeholder="UPC code..." onChange={this.handleUPCChange} />
                        <div className="input-group-append">
                            <input className="btn btn-primary ml-2" type="submit" value="Add"></input>
                            <Button onClick={this.handleItemUpdateClick} variant="warning" className="ml-2"> Update Items </Button>
                            <Button onClick={this.handleItemDeleteClick} variant="danger" className="ml-2"> Delete Items </Button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default UPCSearchBox;