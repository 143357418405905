import React from 'react';
import { Button, Modal, Badge } from "react-bootstrap"

const Popup = (props) => {

    const { showPopup, handlePopupClose, title, message, error } = props;



    const handleClose = () => handlePopupClose(false);

    return (
        <>
            <Modal show={showPopup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><Badge variant="danger">{title}</Badge></Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Popup;

