import React, { Component } from "react";

class TableHeader extends Component {

    render() {
        return (
            <thead>
                <tr key="headerRowKey">
                    <th key={"numCol"}>#</th>
                    {this.props.columns.map((column, index) => (
                        <th key={column.label + index}>
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
