import React, { Component } from 'react';
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import ConfirmDialog from './common/ConfirmDialog';
import { updateManifestRequest } from '../services/websockets'
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"
import NavBar from './NavBar';

class LotSummary extends Component {

    state = {
        lotData: null
    }

    // componentDidMount() {
    //     const { lotData } = this.props;
    //     console.log("LotSummary.componentDidMount lotData: ", lotData);
    //     this.setState({ lotData });
    // }

    componentWillReceiveProps(nextProps) {
        const { lotData } = nextProps;
        console.log("LotSummary.componentWillReceiveProps lotData: ", lotData);
        this.setState({ lotData });
    }


    handleChangeTitleClick = () => {
        console.log("handleChangeTitleClick");

        const dialogConfirm = {
            showConfirmDialog: true,
            title: "Lot Title",
            message: "Enter Lot Title",
            // handleClose: this.handleConfirmDialogClose,
            proceedCallBack: this.handleLotTitleChange,
            proceedButtonLabel: "Change",
            inputBox: true,
            inputPlaceHolder: "",
            defaultValue: this.state.lotData.titleListing
        };
        ConfirmDialog.showConfirmDlg(dialogConfirm);

    }

    handleUpdateManifestMessage = (data) => {
        const lotData = data[0][0];

        console.log("Lots.handleMenifetCreatedMessage: ", lotData);
        playFound();

        this.setState({ lotData });
    }

    handleUpdateManifestMessageError = (data) => {
        console.log("Lots.handleMenifetCreatedMessageError: ", data[0]);
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">{"Error updating lots:" + data[0]}</span>);
    }

    handleLotTitleChange = (title) => {
        const { lotData } = this.state;

        console.log("handleLotTitleChange() newTitle: ", title);
        console.log("handleLotTitleChange() lotData.titleListing: ", lotData.titleListing);
        if (lotData.titleListing == title) {
            console.log("No change");
        } else {
            console.log("title changed, update...");
            updateManifestRequest(title, lotData.id, this.handleUpdateManifestMessage, this.handleUpdateManifestMessageError);
        }


    }


    render() {
        const { lotData } = this.state;
        console.log("LotSummary.render lotData:", lotData);
        return (

            <Container>
                < Row >
                    <Col><h6>Lot Edit {lotData ? " #: " + lotData.lotNumber + " Title: " + lotData.titleListing : ""} {lotData && <Button variant="link" onClick={this.handleChangeTitleClick}>Edit</Button>}</h6></Col>
                </Row >

                <Row>
                    <Col>pallets: {lotData && lotData.numberOfPallets}</Col>
                    <Col>Retail: {lotData && lotData.to}</Col>
                </Row>
                <Row>
                    <Col>cartons: {lotData && lotData.numberOfCartons}</Col>
                    <Col>Your cost:</Col>
                </Row>
                <Row>
                    <Col>units: {lotData && lotData.numberOfUnits}</Col>
                    <Col>Listing Cost:</Col>
                </Row>
            </Container>

        );
    }
}

export default LotSummary;