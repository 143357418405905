import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Table from './common/Table';
import CheckboxSelect from './common/CheckboxSelect';

const BoxesTable = (props) => {
    const columns = [
        {
            key: "ckeckbox",
            label: "Select",
            content: item => (
                <CheckboxSelect key={item.id + "CB"} item={item} checkboxClick={() => props.checkboxClick(item)} />
            )
        },
        { path: "id", label: "Box Id", contentEditable: false, isNumber: true },
        { path: "label", label: "Label", contentEditable: true, isNumber: false },
        { path: "userId", label: "User Id", contentEditable: false, isNumber: false },
        { path: "manualManiestId", label: "Manifest Id", contentEditable: false, isNumber: true },

        { path: "H", label: "H", contentEditable: false, isNumber: false, style: { color: 'blue' } },
        { path: "W", label: "W", contentEditable: false, isNumber: false, style: { color: 'blue' } },
        { path: "L", label: "L", contentEditable: false, isNumber: false, style: { color: 'blue' } },
        { path: "Lbs", label: "Lbs", contentEditable: false, isNumber: true, style: { color: 'green' } },
        { path: "Ozs", label: "Ozs", contentEditable: false, isNumber: true, style: { color: 'green' } },
        { path: "aisle", label: "A", contentEditable: false, isNumber: false, style: { color: 'brown' } },
        { path: "col", label: "C", contentEditable: false, isNumber: false, style: { color: 'brown' } },
        { path: "row", label: "R", contentEditable: false, isNumber: false, style: { color: 'brown' } },
        { path: "bin", label: "B", contentEditable: false, isNumber: false, style: { color: 'brown' } },
        { path: "zone", label: "Z", contentEditable: false, isNumber: false, style: { color: 'brown' } },
        {
            path: "edit",
            label: "Edit",
            content: box => <Link to={`${process.env.PUBLIC_URL}//boxedit/${box.id}`}>Edit</Link>
        }


    ];
    return (<Table columns={columns} items={props.boxes} />);
}

export default BoxesTable;






// class BoxesTable extends Component {

//     const columns = [
//         { path: "id", label: "Box Id", contentEditable: false, isNumber: true },
//         { path: "label", label: "Label", contentEditable: false, isNumber: false },
//         { path: "userId", label: "User Id", contentEditable: false, isNumber: false },
//         { path: "manualManiestId", label: "Manifest Id", contentEditable: true, isNumber: true },

//         { path: "H", label: "H", contentEditable: false, isNumber: false },
//         { path: "W", label: "W", contentEditable: false, isNumber: false },
//         { path: "L", label: "L", contentEditable: false, isNumber: false },
//         { path: "Lbs", label: "Lbs", contentEditable: false, isNumber: true },
//         { path: "Ozs", label: "Ozs", contentEditable: false, isNumber: true },
//         { path: "aisle", label: "aisle", contentEditable: false, isNumber: false },
//         { path: "row", label: "row", contentEditable: false, isNumber: false },
//         { path: "bin", label: "bin", contentEditable: false, isNumber: false },
//         { path: "col", label: "col", contentEditable: false, isNumber: false },
//         { path: "zone", label: "zone", contentEditable: false, isNumber: false }
//     ];


//     render() {
//         return (
//             <div>

//             </div>
//         );
//     }
// }

// export default BoxesTable;