import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap"
import {
    createBoxRequest
} from '../services/websockets'
import NavBar from './NavBar';
import { playFound, playFoundExisting, playNotFound, initSounds } from "../services/sounds"


class NewBoxDialog extends Component {

    static = {
        self: null
    }

    state = {
        title: "",
        show: false
    }

    static showConfirmDlg() {
        if (NewBoxDialog.self) {
            NewBoxDialog.self.setState({ show: true });
        }
    }

    componentDidMount() {
        NewBoxDialog.self = this;
    }

    validate = () => {
        if (this.state.title.trim() === "") {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        console.log("handleSubmit() creating new, titale: ", this.state.title);

        if (this.validate()) {
            console.log("handleSubmit() creating new, lotId: ", (this.props.lotId ? this.props.lotId : 0));
            //   createBoxRequest(this.state.title, this.createdBoxMessageCallback);
            createBoxRequest(this.state.title, (this.props.lotId ? this.props.lotId : 0), this.createdBoxMessageCallback, this.errorMessageProcess);
        }
    }

    errorMessageProcess = () => {
        console.log("errorMessageProcess");
        playNotFound();
        NavBar.showMessage(<span className="badge badge-danger">Error creating box.</span>);
        this.handleClose();
    }



    createdBoxMessageCallback = (data) => {
        console.log("createdBoxMessageCallback: ", data);
        const createdBox = data[0][0];
        console.log("createdBoxMessageCallback : ", createdBox);
        if (this.props.processCreatedBox) {
            this.props.processCreatedBox(createdBox);
        }
        console.log("this.state.createdBox : ", this.state.createdBox);
        NavBar.showMessage(<span className="badge badge-success">Box successfully created {createdBox.label ? "Label: " + createdBox.label : ""} {createdBox.id ? " , id: " + createdBox.id : ""}.</span>);
        this.handleClose();
    }

    handleChange = ({ currentTarget: input }) => {
        console.log("handleChange:", input.value);
        //const title = this.state.title;
        const title = input.value;
        this.setState({ title });
    };


    handleClose = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Lot</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h3 style={{ marginTop: 50 }}> Box Label:</h3>
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <input autoFocus type="text" className="form-control" placeholder="Box Label..." onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <input disabled={!this.validate()} className="btn btn-primary ml-2" type="submit" value="Create Box"></input>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default NewBoxDialog;