import React, { Component } from 'react';
import Table from './common/Table';
import CheckboxSelect from './common/CheckboxSelect';

class ImageTable extends Component {
    bucketUrl = 'https://smalllotimages.s3.amazonaws.com/';

    columns = [
        {
            key: "ckeckbox",
            label: "Select",
            content: image => (
                <CheckboxSelect key={image.id + "CB"} item={image} checkboxClick={() => this.props.checkboxClick(image)} />
            )
        },
        {
            key: "preview",
            label: "PREVIEW (click for full screen)",
            content: image => (
                <img src={this.bucketUrl + image.smallImage} className={"preview"} alt={this.bucketUrl + image.fileName} />
            )
        },
        {
            key: "imageLink",
            label: "click the link(opens new window)",
            content: image => (
                <a target="_blank" href={this.bucketUrl + image.fileName} alt={this.bucketUrl + image.fileName}> {this.bucketUrl + image.fileName}</a>
            )
        }
        // ,
        // { path: "UPC", label: "UPC", contentEditable: false, isNumber: true },
        // { path: "quantity", label: "Qnty", contentEditable: true, isNumber: true }
    ];

    // '<td><img src=\'' + bucketUrl + wine.smallImage + '\' class="preview" ' + 'alt="' + urlLnk + '" /></td>' +
    //     '<td><a target="_blank" href=\'' + urlLnk + '\' alt="' + urlLnk + '">' + urlLnk + '</a></td>' +


    render() {
        return (
            <div>
                <Table columns={this.columns} items={this.props.items} />
            </div>
        );
    }
}

export default ImageTable;