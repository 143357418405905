import React, { Component } from 'react';
import { Button, Modal, Spinner } from "react-bootstrap"
import { addMessageHandler, removeHandler } from '../services/websockets'
import { UPLOAD_FILE_FOR_MANIFEST, sendRawData } from '../services/websockets'
import NavBar from './NavBar';

class FileUploaderSpinner extends Component {

    static = {
        self: null
    }

    state = {
        imagesToUpload: null,
        currentImageIndex: null,
        // imagesUploaded: null,
        imageBeingUploaded: null,
        lotData: null,
        handleUploadCompletion: null,
        handleUploadCancel: null,
        addImage: null,
        show: false
    }

    static uploadFiles(imagesToUpload, lotData, addImage, handleUploadCompletion, handleUploadCancel) {
        if (FileUploaderSpinner.self) {
            let imageBeingUploaded = null;
            if (imagesToUpload && imagesToUpload.length > 0) {
                imageBeingUploaded = imagesToUpload[0];
            }

            const imagesToUploadLoc = [...imagesToUpload];

            //FileUploaderSpinner.self.setState({ show: true, imagesToUpload, imageBeingUploaded, lotData, handleUploadCompletion, handleUploadCancel });
            FileUploaderSpinner.self.startUpload(imagesToUploadLoc, lotData, addImage, handleUploadCompletion, handleUploadCancel);
        }
    }

    startUpload = (imagesToUpload, lotData, addImage, handleUploadCompletion, handleUploadCancel) => {
        let imageBeingUploaded = null;
        let currentImageIndex = 0;
        if (imagesToUpload && imagesToUpload.length > 0) {
            imageBeingUploaded = imagesToUpload[currentImageIndex];
        }
        this.setState({ show: true, imagesToUpload, imageBeingUploaded, lotData, addImage, handleUploadCompletion, handleUploadCancel });
        console.log("startUpload imagesToUpload: ", this.state.imagesToUpload);
        UPLOAD_FILE_FOR_MANIFEST(lotData.id, imageBeingUploaded.name, this.handleUploadReady, this.handleUploadReadyError);
    }

    handleUploadReady = (data) => {
        console.log("handleUploadReady", data);
        this.uploadFile();
    }

    uploadFile = () => {

        var file = this.state.imageBeingUploaded;


        var reader = new FileReader();
        var rawData = new ArrayBuffer();
        reader.loadend = function () {
            console.log("xxxxxxxxxxxx load end");
            /*      const currentFileIndex = this.state.currentFileIndex + 1;
                  if (currentFileIndex == this.state.selectedFiles.length) {
                      console.log("All files uploaded!");
                      NavBar.showMessage(<span className="badge badge-success">All Files uploaded!</span>);
                      playFound();
                      this.setState({ selectedFiles: null, currentFileIndex: null });
                  } else {
                      this.setState({ currentFileIndex });
                      UPLOAD_FILE_FOR_MANIFEST(this.state.lotId, this.handleUploadReady, this.handleUploadReadyError);
                  }
                  */
        }

        reader.onload = function (e) {
            rawData = e.target.result;
            sendRawData(rawData);
            console.log("the File has been transferred, waiting for server to acknowledge end of upload.");
        }

        reader.readAsArrayBuffer(file);

    }


    handleUploadReadyError = (err) => {
        console.log("handleUploadReadyError", err[0]);
        NavBar.showMessage(<span className="badge badge-danger">{err[0]}</span>);
    }

    componentDidMount() {
        FileUploaderSpinner.self = this;
        console.log("FileUploaderSPinner.componentDidMount()");
        addMessageHandler("IMAGE_UPLOADED", this.imageUploadedHandler);
    }

    componentWillUnmount() {
        FileUploaderSpinner.self = null;
        removeHandler(this.imageUploadedHandler);
    }


    imageUploadedHandler = (data) => {
        console.log("imageUploadedHandler data:", data);
        const img = data.images[0];
        if (img && this.state.addImage && this.state.addImage != null) {
            this.state.addImage(img);
        }

        if (this.state.currentImageIndex + 1 >= this.state.imagesToUpload.length) {

            console.log("FileUploaderSpinner.imageUploadedHandler this.state.currentImageIndex + 1 >= this.state.imagesToUpload.length resetting the state....");
            this.setState({
                imagesToUpload: null,
                currentImageIndex: null,
                imageBeingUploaded: null,
                lotData: null,
                handleUploadCompletion: null,
                handleUploadCancel: null,
                addImage: null,
                show: false
            });

            if (this.state.handleUploadCompletion)
                this.state.handleUploadCompletion();


        } else {

            let { currentImageIndex } = this.state;
            ++currentImageIndex;
            const imageBeingUploaded = this.state.imagesToUpload[currentImageIndex];

            this.setState({ show: true, imageBeingUploaded, currentImageIndex });
            console.log("imageUploadedHandler imagesToUpload: ", this.state.imagesToUpload);
            UPLOAD_FILE_FOR_MANIFEST(this.state.lotData.id, imageBeingUploaded.name, this.handleUploadReady, this.handleUploadReadyError);

        }
    }


    handleClose = () => {
        console.log("FileUploaderSpinner... close");
    }




    render() {
        console.log("FileUploaderSpinner.imageBeingUploaded: ", this.state.imageBeingUploaded);
        if (this.state.imageBeingUploaded) {
            return (
                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>File Upload in Progress...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <Spinner className="my-3" animation="border" variant="primary" role="status">
                                <span className="sr-only">Uploading...</span>
                            </Spinner><br />
                            <span>{this.state.imageBeingUploaded && "Uploading " + (this.state.currentImageIndex + 1) + " of " + this.state.imagesToUpload.length + " file: " + this.state.imageBeingUploaded.name}</span>
                        </center>
                    </Modal.Body>
                </Modal >
            );
        } else {
            return ("");
        }
    }

}

export default FileUploaderSpinner;